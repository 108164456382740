/**
 * @file Styles pour les Sliders (avec Splide)
 * @author Christine Daneau-Pelletier <infocotegeek@gmail.com>
*/

/**
 * ------------------------------------------------------
 * TABLE DES MATIÈRES
 * ------------------------------------------------------
 * Bouton de pagination
 * Dark theme
 */

.splide {
  // Bouton de pagination
  // ----------------------------------
  &__pagination { // ul
    width: fit-content;
    bottom: -32px;
    top: initial;
    left: 60px;
    margin: 0 24px;

    @media (min-width: $tablette) {
      bottom: -20px;
      margin: 0 48px;
    }

    @media (min-width: $bureau) {
      bottom: 4vw;
      left: calc(4vw + 60px);
    }

    @media (min-width: $large) {
      bottom: 8vw;
    }

    &__page { // button
      width: 10px;
      height: 10px;
      margin: 12px;
      background: var(--couleurAdmin);
      opacity: 1;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all .25s ease-in-out;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: $blanc;
        transform: scale(0);
        transition: all .25s ease-in-out;
      }

      &.is-active {
        transform: scale(2);
        background: var(--couleurAdmin);

        &:before {
          transform: scale(.5);
        }
      }

      // dark theme
      // ----------------------------------
      .dark & {
        @media (min-width: $tablette) {
          background: $blanc;

          &:before {
            background: var(--btn);
          }
        }
      }
    }
  }

  // Chevrons - arrows
  // ----------------------------------
  &__arrow {
    background: none;
    opacity: 1;
    transition: all .2s ease-in-out;

    &--prev {
      left: 0;

      &:not([disabled]):hover {
        left: -6px;
      }
    }

    &--next {
      right: 0;

      &:not([disabled]):hover {
        right: -6px;
      }
    }
  }
}
/**
 * @author Christine Daneau-Pelletier <infocotegeek@gmail.com>
*/

/******************************************************************/
/* GRILLE SUSY */
/******************************************************************/
@include border-box-sizing;

@media(max-width:(($bureau)-1px)){
  $susy: (
          'columns': susy-repeat(4),
          'gutters': 0.25,
          'spread': 'narrow',
          'container-spread': 'narrow',
  );
  .conteneur {
    //background: susy-svg-grid($susy) no-repeat scroll;
  }
}
@media(min-width:$bureau){
  $susy: (
          'columns': susy-repeat(12),
          'gutters': 0.25,
          'spread': 'narrow',
          'container-spread': 'narrow'
  );
  .conteneur {
    //background: susy-svg-grid($susy) no-repeat scroll;
  }
}

/******************************************************************/
/* ESPACEMENT */
/******************************************************************/
.conteneur {
  margin: 0 12px;
  max-width: 1200px;

  @media (min-width: $miniMobile) {
    margin: 0 24px;
  }

  @media (min-width: $tablette) {
    margin: 0 48px;
  }

  @media (min-width: 1296px) {
    margin: 0 auto;
  }
}

.block {
  margin-top: 60px;
  margin-bottom: 60px;

  @media (min-width: $tablette) {
    margin-top: 120px;
    margin-bottom: 120px;
  }

  @media (min-width: $huge) {
    margin-top: 160px;
    margin-bottom: 160px;
  }

  &--pad {
    padding: 60px 0;

    @media (min-width: 1024px) {
      padding: 80px 0;
    }

    @media (min-width: $medium) {
      padding: 120px 0;
    }

    @media (min-width: $huge) {
      padding: 160px 0;
    }
  }
}
/**
 * @file Styles généraux & communs du site
 * @author Christine Daneau-Pelletier <infocotegeek@gmail.com>
*/

/**
 * ------------------------------------------------------
 * TABLE DES MATIÈRES
 * ------------------------------------------------------
 * Styles généraux
 * -- Reset
 * Hyperliens
 * Accodéons
 * 404
 * Animations
 */


// Styles généraux
// ----------------------------------
html {
  scroll-behavior: smooth;
  background: $blanc;
  overflow-x: hidden;
}

.contenu {
  overflow-x: hidden;

  @media (max-width: $mobile) {
    padding-top: 70px;
  }
}

img, .actualite img {
  width: 100%;
  min-width: 100%;
  height: auto;
  vertical-align: bottom;
}

::selection {
  background: $bouteille;
}
:focus {
  outline-color: $bleu;
  outline-offset: 5px;

  @media (max-width: $tablette) {
    outline: none;
  }
}
[tabindex='0']{
  cursor: pointer;
}

img.demi {
  width: 50%;
  min-width: initial;
}

img.tier {
  width: 33%;
  min-width: initial;
}


// Reset
figure, picture {
  margin: 0;
  display: block;
}

.resetList, .menu {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.resetHeight {
  height: initial;
}

@media (min-width: $mobile) {
  .alignright {
    margin: 0 20px 10px 0;
    float: left;
  }
  .alignleft {
    margin: 0 0 10px 20px;
    float: right;
  }
}

.avatar {
  min-width: initial;
}

  // Hyperliens
// ----------------------------------
a, .credits__btn {
  display: inline-block;
  text-decoration: none;
  transition: all .25s ease-in-out;
  color: $tiel;
  font-weight: 600;

  &:hover, &:focus {
    color: $noir;
    text-shadow: 0 0 0 $noir;
  }

  .darkBox & {
    color: #FFD37D;

    &:hover, &:focus {
      opacity: .4;
      color: $blanc;
    }
  }
}

.underlines {
  position: relative;

  &:before, &:after {
    transition: all .28s ease-in-out;
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background: $charcoal;
    z-index: -1;
    transform: scaleX(0);
  }

  &:before {
    bottom: -3px;
    left: -5px;
    transform-origin: left;
  }

  &:after {
    bottom: -10px;
    right: -5px;
    transform-origin: right;
  }

  &:hover, &:focus, &.actif {
    outline: 0;

    &:before, &:after {
      transform: scaleX(1);
    }
  }
}

.lien--retour {
  display: flex;
  align-items: center;
  color: $charcoal;
  margin: 40px 0 20px;

  svg {
    width: 40px;
    transform: scaleX(-1);
    transition: all .2s ease-in-out;

    @media (min-width: $tablette) {
      width: 52px;
    }
  }

  span {
    display: inline-block;
    width: fit-content;
  }

  .icone {
    margin: 0 16px 8px 0;

    @media (min-width: $tablette) {
      margin: 0 16px 20px 0;
    }
  }

  &:hover, &:focus {
    svg {
      transform: translateX(-10px) scaleX(-1);
    }
  }
}

.allerContenu {
  transform: translate(20px, 120px);

  &--user {
    transform: none;
    padding: 0 20px;
    line-height: 3;
    outline-offset: -5px;
  }
}


//  -> il n'y pas assez styles pour que ça vaille la peine de lui faire un module

// Accodéons
// ----------------------------------
.accordeon {
  &__conteneur {
    transition: height 0.65s cubic-bezier(.6, 0, .18, 1);
    overflow: hidden;
  }

  &__trigger {
    &:hover, &:focus {
      outline: none;

      .icone--chevron {
        margin-left: 10px;
      }
    }
  }
}

// Copyrights d'images
// ----------------------------------
.copyrights {
  position: absolute;
  top: calc(100% - 36px);
  left: 16px;
  opacity: 0;
  transition: opacity .2s ease-in-out;
  z-index: 2;

  &__tx {
    display: inline-block;
    border-radius: 50px;
    padding: 0 10px 0 24px;
    background: $gris;
    line-height: 1.75;
    @include fontSize(14px);
    @include fontSize(14px);
    opacity: 0;
    transform: translateX(-2px);
    transition: all .25s ease-in-out;
  }

  .icone--info {
    width: 26px;
    height: 26px;
    position: absolute;
    top: 2px;
    z-index: 1;

    svg {
      height: 16px;
    }
  }

  &:hover, &:focus {
    .copyrights__tx {
      opacity: 1;
      transform: translateX(10px);
    }

    .icone--info {
      box-shadow: 0 0 0 0 var(--medium);
    }
  }

  &__conteneur {
    &:hover, &:focus {
      .copyrights {
        opacity: 1;
      }
    }

  }
}

// Page 404
// ----------------------------------
.page404 {
    min-height: 24vh;

  @media (min-width: $mobile) {
    min-height: 44vh;
  }

  @media (min-width: $medium) {
    min-height: 388px;
  }
}

// Page Single fallback
// ----------------------------------
.singleFB {
  img {
    width: auto;
    min-width: auto;
    margin: 40px auto;
  }
}


// Animations
// ----------------------------------
@keyframes animIN1 {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes animIN2 {
  0% {
    clip-path: polygon(0% 100%, 6% 100%, 6% 100%, 0% 100%);
  }
  50% {
    clip-path: polygon(0% 0%, 6% 0%, 6% 100%, 0% 100%);
  }
  100% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
}
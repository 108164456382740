/**
 * @file Formulaire
 * @author Christine Daneau-Pelletier <infocotegeek@gmail.com>
*/

/**
 * ------------------------------------------------------
 * TABLE DES MATIÈRES
 * -----------------------------------------------------
 * Styles généraux
 * Erreurs
 * Validations
 * reCaptcha
 * plug in
 */


// Styles généraux
// ------------------------------------------------------
label {
  display: block;
  padding-bottom: 6px;
}

input:not([type="radio"], [type="checkbox"]), textarea, select {
  transition: border-color .18s ease-in-out;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 12px 16px;
  width: 100%;
  box-shadow: -1px -1px 1px 1px #fff, 1px 1px 7px 0px #eaeaea;

  &:focus {
    outline: 0;
    border-color: #E2CFA9;
  }
}

textarea {
  resize: vertical;
}

[type="file"] {
  background: #fff;
}

button {
  border: none;
  background: none;
  padding: 0;
}

.form__titre {
  @media (min-width: $large) {
    @include fontSize(32px);
  }
}

/* Erreurs */
/*-----------------------------------*/
.erreur {
  border-color: $peach;
}

.msgErreur {
  color: $erreur;

  &:empty {
    margin: 0;
  }
}

.ok {
  border-color: $bordeau;
}

/* Validations */
/*-----------------------------------*/
.ctnForm {
  position: relative;
  margin: 24px 0;

  @media (min-width: $tablette) {
    margin: 32px 0;
  }

  /* reCaptcha */
  /*-----------------------------------*/
}

/* Plug in */
/*-----------------------------------*/
.wpforms-field {
  margin: 40px 0;
}

.wpforms-container {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .wpforms-field-radio,
  .wpforms-field-checkbox {
    li {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;

      label {
        padding: 0;
      }
    }
  }

  button.wpforms-submit {
    @extend .btn;
  }
}
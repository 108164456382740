/**
 * @file Styles de la navigation
 * @author Christine Daneau-Pelletier <infocotegeek@gmail.com>
*/
/**
 * ------------------------------------------------------
 * TABLE DES MATIÈRES
 * ------------------------------------------------------
 * Navigation
 * -- top
 * -- main
 * -- menu fermé
 * Item de menu <li> & <a>
 * Logo
 * Animations
 * --
 */


// Navigations
// ------------------------------------------------------
.nav {
  width: 100%;

  @media (max-width: $mobile) {
    position: fixed;
    z-index: 5;
  }

  @media (min-width: $mobile) {
    display: flex;
    flex-flow: row nowrap;
    padding: 20px;
  }

  // top - pour le mobile surtout
  &__top {
    @media (max-width: $mobile) {
      background: $blanc;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }
  }

  // main
  &__main {
    transition: transform .5s ease-in-out;

    @media (max-width: $mobile) {
      box-shadow: 0 0 24px -8px rgba(0, 0, 0, .5);
      padding-bottom: 32px;
      background: $blanc;
      position: absolute;
      top: 70px;
      width: 100%;
      z-index: 0;
    }
  }

  // état du menu fermer
  &--fermer {
    .nav__main {
      transform: translateY(-100%);
    }

    .menu-item {
      transform: translateY(-5px);
      opacity: 0;
    }

    .menuBtn__ligne {
      opacity: 1;
      transition:  top .3s ease-in-out .3s,  transform .3s ease-in-out, opacity 0s ease-in-out .3s;

      &--1 {
        top: 0;
      }

      &--2, &--3 {
        transform: rotate(0);
      }

      &--4 {
        top: calc(100% - 2px);
      }
    }
  }
}


// Item de menu <li> & <a>
// ------------------------------------------------------
.menu-item {
  transform: translateY(0);
  opacity: 1;
  transition: all .25s ease-in-out;

  @media (min-width: $mobile) {
    display: inline-block;
  }

  @for $i from 1 through 4 {
    $calc: ($i * 0.15s) + .25s;
    &:nth-of-type(#{$i}) {
      transition-delay: $calc;
    }
  }

  a {
    text-transform: uppercase;
    font-weight: 500;
    color: $noir;
    padding: 10px 20px;
    @extend .underlines;

    @media (max-width: $mobile) {
      text-align: center;
      width: 100%;

      &:before, &:after {
        opacity: 0;
      }
    }

    @media (min-width: $mobile) {
      margin: 24px 10px 22px 20px;
      padding: 0;
    }

    @media (min-width: $tablette) {
      margin: 24px 10px 22px 40px;
    }

    @media (min-width: $medium) {
      margin: 24px 10px 22px 60px;
    }

    // États
    .dark & {
      @media (min-width: $medium) {
        color: $blanc;
        opacity: .75;

        &:before, &:after {
          background: $blanc;
          opacity: .75;
        }
      }
    }
  }
}

// États
.nav .actif {
  color: $aqua;
  cursor: initial;

  &:before, &:after {
    background: $bleu;
  }
}

// Logo
// ------------------------------------------------------
.logo {
  width: 50px;
  height: 50px;

  &__conteneur {
    padding: 10px;
    display: inline-flex;
    opacity: .65;
    color: $charcoal;

    &:hover, &:focus {
      color: $charcoal;
      opacity: .85;
    }

    .dark & {
      @media (min-width: $medium) {
        color: $blanc;

        &:hover, &:focus {
          color: $blanc;
        }
      }
    }
  }
}


// SI on est dans l'accueil, on va par dessus le banner
// ------------------------------------------------------
.home {
  @media (min-width: $medium) {
    .nav {
      position: absolute;
      z-index: 5;
    }
  }
}
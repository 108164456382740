/**
 * @file Styles pour la page - Nous joindre
 * @author Christine Daneau-Pelletier <infocotegeek@gmail.com>
*/

/**
 * ------------------------------------------------------
 * TABLE DES MATIÈRES
 * ------------------------------------------------------
 * Head - banner & title
 * -- cadre
 * Fenêtre modale
 * Animations
 */


// Head - banner & title
// ------------------------------------------------------
.contact {
  &__forms {
    position: relative;

    @media (min-width: $tablette) {
      // flex tablet
      display: flex;
      justify-content: space-between;
    }

    &_item {
      max-width: 480px;
      margin-left: auto;
      margin-right: auto;

      @media (min-width: $tablette) {
        max-width: 400px;
        width: calc(50% - 32px);
      }
    }

    // Décoration des lignes au centre
    &:before , &:after {
      position: absolute;
      content: '';
      background: $ble;
      height: 70%;
      width: 1px;
      left: calc(50% + 1px);
      top: 15%;
      opacity: 0;

      @media (min-width: $bureau) {
        opacity: 1;
      }
    }

    &:before {
      transform: translate(-10px, -40px);
    }

    &:after {
      transform: translate(10px, 40px);
    }
  }

  &__titre {
    position: relative;
    padding: 20px 20px 10px;
    text-align: center;
    background: #f1f1f1;
    transform: translateX(-10px);

    @media (min-width: $tablette) {
      padding: 40px 20px 30px;
    }

    &_tx {
      margin: 0;
    }

    // cadre décoratif
    &:after {
      content: '';
      position: absolute;
      top: 10px;
      left: 10px;
      border: 1px solid rgba($ble, .75);
      width: 100%;
      height: 100%;

      @media (min-width: $tablette) {
        top: 16px;
        left: 20px;
      }
    }
  }
}


// Fenêtre modale
// ------------------------------------------------------

/* The Modal (background) */
.modale {
  position: fixed;
  z-index: -1;
  left: 0;
  top: 200px;
  width: 100%;
  height: 100%;
  transition: all .25s ease-in-out;

  &__contenu {
    position: relative;
    background-color: $blanc;
    margin: auto;
    border: 20px solid #eaeaea;
    width: 90%;
    max-width: 480px;
    padding: 20px 20px 16px;
    text-align: center;
    box-shadow: 2px 4px 70px 0 rgba(66, 66, 66, 0.3), 0 0 12px -1px rgba(66, 66, 66, 0.3);
  }

  p {
    margin: 0 0 6px;
  }

  .titre {
    margin: 1rem 0;
  }

  &.footer__modale {
    opacity: 0;
    z-index: -1;
  }

  &.ouvrir {
    top: 220px;
    opacity: 1;
    z-index: 5;
  }

  // icone pour fermer
  .icone--x {
    position: absolute;
    top: 10px;
    right: 10px;

    &:hover, &:focus {
      cursor: pointer;
    }
  }
}

// Animations
// ------------------------------------------------------

//  Fenêtre modales
@keyframes animModal {
  0%, 99.99%  {
    top: 200px;
    opacity: 0;
    z-index: 5;
  }
  10%, 90% {
    top: 220px;
    opacity: 1;
  }
  100% {
    top: 200px;
    opacity: 0;
    z-index: -1;
  }
}

.animModal {
  animation: animModal 3.25s .2s ease-in-out both;
}

@keyframes animModal2 {
  0% {
    top: 200px;
    opacity: 0;
  }
  100% {
    top: 220px;
    opacity: 1;
    z-index: 5;
  }
}

// Les responsables
@keyframes animRespo {
  from {
    transform: translateX(-20px);
    opacity: 0;
  }
  to {
    transform: translate(0);
    opacity: 1;
  }
}

.animRespo {
  &:nth-of-type(1) {
    --delay: 0s;
  }
  &:nth-of-type(2) {
    --delay: .2s;
  }
  &:nth-of-type(3) {
    --delay: .4s;
  }
  &:nth-of-type(4) {
    --delay: .6s;
  }

  @media (min-width: $bureau) {
    animation: animRespo .4s var(--delay) cubic-bezier(0.43, 0, 0.3, 1.15) both;
  }
}
/**
 * @file Styles pour la page Répertoire
 * @author Christine Daneau-Pelletier <infocotegeek@gmail.com>
*/

/**
 * ------------------------------------------------------
 * TABLE DES MATIÈRES
 * ------------------------------------------------------
 * Période musicale
 * -- Colors
 * --  Image & title & tigger
 * --  Chevron
 * Liste des pistes audios - répertoire
 * Oeuvre
 * Audio player
 */

// Période musicale
// ----------------------------------
.periode {
  margin: 60px 0;
  transition: width .4s ease-in-out;

  //colors
  &--rb {
    --light: #EFE3DF;
    --medium: #FFDBD6;
    --border: #{$coral};
    --neon: #{$coral};
    --dark: #{$rouge};
  }
  &--cr {
    --light: #E3EBDE;
    --medium: #E3EBDE;
    --border: #{$vert};
    --neon: #{$vert};
    --dark: #{$foret};
  }
  &--xxxxi {
    --light: #F6F0E3;
    --medium: #F7E5C2;
    --border: #{$jaune};
    --neon: #{$jaune};
    --dark: #{$ocre};
  }

  @media (min-width: $tablette) {
    width: 33.33%;
    margin: 0;
  }

  &__conteneur {
    position: relative;
    transition: height .4s ease-in-out;

    @media (min-width: $tablette) {
      display: flex;
      flex-flow: row wrap;
      height: 50vw;
      overflow: hidden;
    }

    @media (min-width: $medium) {
      height: 630px;
    }
  }

  // Image & title & tigger
  &__head {
    position: relative;
    padding: 0 32px 20px;
    transition: all .5s ease-in-out, margin-top .25s ease-in-out;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 100%;
      background: var(--light);
      z-index: -1;
      transition: background .5s ease-in-out, width .45s ease-in-out .3s;
    }

    &:focus {
      outline: none;
    }

    @media (min-width: $mobile) AND (max-width: $tablette) {
      height: 80px;
      overflow: hidden;
    }

    @media (min-width: $tablette) {
      height: 50vw;
      padding: 0 32px 32px;
      margin-top: 20px;

      &:hover{
        outline: none;
        margin-top: 0;

        .periode__titre {
          text-shadow: 0 0 1.5px $noir;
        }
      }

      &.actif {
        height: 125px;

        &:before, .periode__titre {
          width: 300%;
          text-align: left;
          text-shadow: 0 0 1.5px $noir;
        }

        .periode__img {
          transform: translateY(-20px);

          &:after {
            border-color: var(--dark);
          }
        }

        .periode__titre {
          padding: 26px 0 24px;
        }
      }

      &:hover {
        .periode__img:after {
          border-color: $charcoal;
        }
      }

      &:hover, &.actif {
        &:before {
          background: var(--neon);
        }
      }
    }

    @media (min-width: $bureau) {
      padding: 0 48px 48px;
    }

    @media (min-width: $medium) {
      height: 50vw;
    }
  }

  &__titre {
    text-align: center;
    width: 100%;
    margin: 0;
    padding: 26px 0 24px;
    transition: all .25s ease-in-out ;

    @media (min-width: $bureau) {
      padding: 52px 0 48px;
      @include fontSize(30px);
    }

    @media (min-width: $medium) {
      padding: 52px 41px 48px;
    }
  }

  &__img {
    position: relative;
    margin: 10px 0 0 10px;
    transition: all .25s ease-in-out;

    &:after {
      content: '';
      position: absolute;
      top: -10px;
      left: -10px;
      border: 1px solid  var(--neon);
      width: 100%;
      height: 100%;
      transition: border-color .25s ease-in-out;

      @media (min-width: $bureau) {
        top: -20px;
        left: -20px;
      }
    }

    @media (min-width: $bureau) {
      margin: 20px 0 0 20px;
    }
  }

  &__cta {
    display: inline-flex;
    justify-content: flex-end;
    width: 100%;
    text-align: right;
    padding-top: 20px;

    &_conteneur {
      margin: 0;
    }
  }

  &__main {
    @media (min-width: $tablette) {
      width: calc(100% - 96px);
      max-width: 1200px;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 48px;
      opacity: 0;
      z-index: 1;
      padding-left: 30%;
    }

    @media (min-width: $medium) {
      margin: 0 auto;
      padding-left: 400px;
    }

    &.actif {
      opacity: 1;
      z-index: 2;
      transition: opacity .25s ease-in-out;
    }

    .periode--rb & {
      transition: opacity .25s ease-in-out .45s;
    }

    .periode--cr & {
      transition: opacity .25s ease-in-out .6s;
    }

    .periode--xxxxi & {
      transition: opacity .25s ease-in-out .75s;
    }
  }

  // Chevron
  .icone {
    width: fit-content;
    height: fit-content;
    color: var(--dark);
    transform: scale(.7);
    margin-left: 8px;

    @media (min-width: $tablette) {
      transform: scale(1);
    }

    &.down {
      transform: rotate(180deg) scale(.7);

      @media (min-width: $tablette) {
        transform: rotate(180deg) scale(1);
      }
    }
  }
}

// Liste des pistes audios - répertoire
// ----------------------------------
.repertoire {
  @media (min-width: $tablette) {
    margin-bottom: 0;

    &__heading {
      margin-top: 80px;
    }

    &__intro {
      width: 75%;
    }
  }

  &__intro {


    img {
      width: initial;
      height: initial;
      min-width: initial;
      vertical-align: sub;
    }
  }

  &__titre {
    line-height: 1;

    @media (min-width: $tablette) {
      border-bottom: 4px solid $bouteille;
      padding: 0 40px 40px 0;
      margin: 40px 0;
      width: fit-content;
    }
  }

  &__list {
    padding: 10px 0 0;
  }

  &__compositeur {
    margin: 20px 0 0;
    text-transform: initial;
    position: relative;

    .icone {
      margin: 0;
      transform: scale(.7) translateY(4px);

      @media (min-width: $mobile) {
        margin: 32px 0 0;
      }

      @media (min-width: $tablette) {
        margin: 32px 0 0;
        transform: scale(1);
      }

      &.down {
        transform: rotate(180deg) scale(.7) translateY(4px);

        @media (min-width: $tablette) {
          transform: rotate(180deg) scale(1);
        }
      }
    }

    &.note {

      @media (max-width: $tablette) {
        padding-left: 24px;
      }

      &:before {
        content: '';
        position: absolute;
        top: -10px;
        left: 0;
        margin: auto 0;
        width: 16px;
        height: 20px;
        transform: translateY(16px);
        background: url("../images/icones/note1.svg");

        .periode--cr & {
          background: url("../images/icones/note3.svg");
        }

        .periode--xxxxi & {
          background: url("../images/icones/note2.svg");
        }

        @media (min-width: $mobile) {
          top: 0;
          bottom: 0;
        }

        @media (min-width: $tablette) {
          left: -26px;
        }
      }
    }
  }

  &__annees {
    @include fontSize(14px);
    letter-spacing: 1.5px;
  }
}

// Oeuvre
// ----------------------------------
.oeuvre {
  padding: 0 0 20px;

  &__list {
    position: relative;
  }

  &__titre {
    @include fontSize(16px);
    line-height: 1.5;
    font-weight: 400;
    margin: 0 0 12px;
  }

  &__medias {
    display: flex;
    grid-gap: 16px;
    align-items: center;
    padding-left: 3px;

    &_link:hover {
      .icone--player {
        box-shadow: 0 0 0 0 var(--medium);
        transform: translate(-3px, -2px);
      }
    }
  }
}

// Audio player
// ----------------------------------
audio {
  width: 100%;
  border: 1px solid var(--neon);
  border-radius: 100px;
  box-shadow: -3px -2px 0 0 var(--medium);
  filter: contrast(1.15);

  &:focus {
    outline-color: transparent;
  }

  @-moz-document url-prefix() {
    width: calc(100% - (54px + 16px));
    filter: none;
    height: min-content;
  }
}

// État ouvert
// ----------------------------------
@media (min-width: $tablette) {
  .repertoire--ouvert {
    margin-bottom: 120px;

    .periode {
      &__conteneur {
        height: 100vh;
      }

      &__head {
        margin-top: 0;
      }
    }

    .periode__head {
      opacity: 0;

      &.actif {
        opacity: 1;
        pointer-events: none;
      }
    }

    .retour {
      opacity: 1;
      z-index: 1;
      transition: all .25s ease-in-out .5s;
    }
  }

  .contenuTable {
    width: 100%;
    height: 1px;
    transition: height 0.65s cubic-bezier(.6, 0, .18, 1);
  }

  .retour {
    position: absolute;
    top: 0;
    right: 0;
    padding: 48px;
    opacity: 0;
    transition: all .25s ease-in-out;
    z-index: -1;

    &:hover, &:focus {
      outline: none;
      transform: translateX(-10px);
      text-shadow: 0 0 black;
    }
  }
}
/**
 * @file Styles de la fiche d'une actualité
 * @author Christine Daneau-Pelletier <infocotegeek@gmail.com>
*/

/**
 * ------------------------------------------------------
 * TABLE DES MATIÈRES
 * ------------------------------------------------------
 * Image
 * -- cadre décoratif
 * Info - liens
 * Colonne de gauche
 * Colonne de droite
 * Flex de table
 * Blockquote
 */


.actualite {
  // Image
  // ----------------------------------
  &__img {
    margin: 16px 0 0 16px;
    position: relative;

    // cadre décoratif
    &:after {
      content: '';
      position: absolute;
      top: -16px;
      left: -16px;
      border: 1px solid $coral;
      width: 100%;
      height: 100%;
    }

    @media (min-width: $mobile) {
      margin: 20px 0 0 20px;

      &:after {
        top: -20px;
        left: -20px;
      }
    }
  }

  // Info - liens
  // ----------------------------------
  &__info, &__source a {
    text-transform: uppercase;
    color: $rouge;
  }

  &__source a {
    color: $rouge;
    font-weight: 900;

    &:hover, &:focus {
      color: $charcoal;
    }
  }

  &__info {
    margin: 28px 0 20px;
  }

  // Colonne de gauche
  // ----------------------------------
  @media (min-width: $mobile) AND (max-width: $tablette) {
    &__gauche {
      display: flex;
      grid-gap: 40px;
      margin: 0 0 40px;
    }

    &__img {
      width: 50%;
    }
  }

  // Colonne de droite
  // ----------------------------------
  &__droite {
    img {
      width: initial;
      min-width: initial;
      height: initial;
    }
  }

  // Flex de table
  // ----------------------------------
  @media (min-width: $tablette) {
    display: flex;
    flex-flow: row wrap;
    grid-gap: 10%;

    &__heading{
      width: 100%;
    }

    &__gauche {
      width: 30%;
      margin: 40px 0;
    }

    &__droite {
      width: 60%;
      margin-top: -76px;
    }

    &__info {
      margin: 40px 0;
    }
  }
}

// Blockquote
// ----------------------------------
blockquote {
  margin: 32px 0;
  border: 1px solid $blanc;
  border-radius: 2px;
  padding: 16px 20px;
  outline: 4px solid  rgba($bouteille, .5);
  background: url("../images/icones/quote.svg") no-repeat 20px 20px / 64px, url("../images/icones/quote2.svg") no-repeat calc(100% - 20px) calc(100% - 20px) / 64px;
  background-color: rgba($bouteille, .5);

  @media (min-width: $tablette) {
    margin: 40px 4px;
    padding: 32px;
  }
}
/**
* @author Ève Février - efevrier@cegep-ste-foy.qc.ca
* @author Yves Hélie - yves.helie@cegep-ste-foy.qc.ca
  @author Christine Daneau-Pelletier <infocotegeek@gmail.com>
*/

/**
 * ----------------------------
 * TABLE DES MATIERES - MIXINS
 * ----------------------------
 * Utilitaires de base
 ** Box sizing
 ** Clearfix
 * Accessibilité
 * Typographie
 * États
 */

/********** Utilitaires de base **********/

/** Box sizing **/
@mixin border-box-sizing {
    html {
        box-sizing: border-box;
    }
    *, *:before, *:after {
        box-sizing: inherit;
    }
}

/* Clearfix */
@mixin clearfix {
    &:after {
        content: " ";
        display: table;
        clear: both;
    }
}

.clearfix {
    @include clearfix;
}

/********* Accessibilité *********/

// Hide only visually, but have it available for screenreaders:
// http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
@mixin visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 0;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 0;

    // Extends the .visuallyhidden class to allow the element to be focusable
    // when navigated to via the keyboard: https://www.drupal.org/node/897638
    &.focusable:active,
    &.focusable:focus {
        clip: auto;
        height: auto;
        margin: 0;
        overflow: visible;
        position: static;
        width: auto;
    }
}

.visuallyhidden,
.screen-reader-only {
    @include visuallyhidden;
}


/******** Typographie *********/

// Fonction pour convertir une valeur de font-size en pixels à son équivalent rem
@function calculateRem($size) {
    $remSize: $size / 10px;
    @return #{$remSize}rem;
}

// Mixin qui inclus la solution de repli en pixel suivi par la valeur calculée en rem
@mixin fontSize($size) {
    font-size: $size;
    font-size: calculateRem($size);
}


/******** STYLES *********/
// --------------------------------------------
.customScrollBar {
    &::-webkit-scrollbar-track {
        background-color: #ececec;
        border-radius: 10px;
    }
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba($ble, .9);
        border-radius: 10px;
    }
    &--dark {
        &::-webkit-scrollbar-track {
            background-color: rgba($blanc, .2);
        }
        &::-webkit-scrollbar-thumb {
            background-color: rgba($ble, .6);
        }
    }
}

/******** portable container *********/
@mixin conteneur {
    margin: 0 12px;
    max-width: 1200px;

    @media (min-width: $miniMobile) {
        margin: 0 24px;
    }

    @media (min-width: $tablette) {
        margin: 0 48px;
    }

    @media (min-width: 1296px) {
        margin: 0 auto;
    }
}

/******** États *********/
// Contrôle d'affichage selon le @media
@media (min-width: $tablette) {
    .mobileOnly {
        display: none !important;
    }
}

@media (max-width: $tablette) {
    .tableOnly {
        display: none !important;
    }
}

.displayNone {
    display: none !important;
}

.close {
    height: 0 !important;
    @media (max-width: $tablette) {
        overflow: hidden;
    }
}

.squeez {
    @media (min-width: $tablette) {
        width: 0 !important;
    }
}

.disabled {
    @media (min-width: $tablette) {
        pointer-events: none;
    }
}
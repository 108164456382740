/**
 * @file Styles de la fiche d'un concert
 * @author Christine Daneau-Pelletier <infocotegeek@gmail.com>
*/

/**
 * ------------------------------------------------------
 * TABLE DES MATIÈRES
 * ------------------------------------------------------
 * Headings
 *
 * Banner
 *
 * Infos
 * -- icones
 * -- prix
 * -- bouton
 *
 * Commanditaires
 *
 * Contenu
 * -- cadre décoratif
 *
 * Artistes
 * -- Grid
 *
 * Page Grid
 */


.concert {
  position: relative;

  // Headings
  // ----------------------------------
  &__heading {
    grid-area: retour;
  }

  &__titre {
    @media (min-width: $large) {
      @include fontSize(60px);
    }
  }

  &__sousTitre {
    margin: 32px 0 20px;
    font-weight: 600;
    @include fontSize(16px);

    @media (min-width: $mobile) {
      @include fontSize(20px);
    }
  }

  // Banner
  // ----------------------------------
  &__img {
    @media (min-width: $tablette) {
      position: absolute;
      top: 0;
      right: -48px;
      width: calc(70% + 48px);
      z-index: -1;
    }
  }

  // Infos
  // ----------------------------------
  &__info {
    font-weight: 500;
    grid-area: info;

    @media (min-width: $tablette) {
      font-weight: 600;
      padding: 56px 0;
    }

    &_flex {
      display: inline-flex;
      align-items: center;
      margin: 10px 0;
      width: 100%;
    }
  }

  // icones
  .icone--info {
    width: 42px;
    height: 42px;
    min-width: 42px;
    min-height: 42px;
    margin-right: 16px;
  }

  // prix
  &__prix {
    span {
      width: min-content;
      margin-right: 16px;
    }
  }

  // bouton
  .btn {
    margin-top: 40px;
  }

  // Commanditaires
  // ----------------------------------
  &__commanditaires {
    a {
      font-weight: 400;
      line-height: 1.4;
    }

    img {
      width: auto;
      max-width: 100%;
      min-width: initial;
      max-height: 140px;
      margin-bottom: 10px;

      @media (min-width: $tablette) {
        max-width: 90%;
      }
    }

    &_item {
      margin-bottom: 32px;
    }

    //fix mobile
    &.mobileOnly {
      margin-bottom: 60px;
    }
  }

  // Contenu
  // ----------------------------------
  &__contenu {
    grid-area: contenu;

    @media (min-width: $tablette) {
      margin-top: 34vw;
    }

    @media (min-width: 1280px) {
      margin-top: 460px;
    }
  }

  &__desc {
    grid-area: desc;
    position: relative;

    @media (min-width: $tablette) {
      background: $charcoal;
      color: $blanc;
      padding: 40px 60px 20px 40px;
      border: 1px solid $blanc;
      box-shadow: 2px 4px 65px -5px rgba(0, 0, 0, 0.4);

      // cadre décoratif
      &:after {
        content: '';
        position: absolute;
        top: 20px;
        left: -20px;
        border: 1px solid $ocre;
        width: 100%;
        height: 100%;
      }
    }
  }

  // Artistes
  // ----------------------------------
  &__artistes {
    &_item {
      margin: 40px 0;

      // Grid
      @media (min-width: $tablette) {
        display: grid;
        grid-template-areas:
          "profil presentation"
          "profil presentationDesc";
      }
    }

    &_head {
      @media (max-width: $tablette) {
        display: flex;
        align-items: center;
      }
    }

    &_img {
      border-radius: 50%;
      overflow: hidden;
      width: 100px;
      min-width: 100px;
      height: 100px;
      margin-right: 20px;
      grid-area: profil;

      @media (min-width: $tablette) {
        width: 120px;
        min-width: 120px;
        height: 120px;
        margin-right: 32px;
      }
    }


    &_nom {
      margin-bottom: 0;

      @media (min-width: $tablette) {
        margin-top: 0;
      }
    }

    &_tag {
      color: #9e6901;
      margin: 6px 0;

      @media (min-width: $tablette) {
        margin-bottom: 0;
      }
    }

    &_desc {
      grid-area: presentationDesc;
    }

    &_lien {
      margin-top: -14px;
      display: block;
      width: 100%;
      text-align: right;
    }

    // fix mobile
    .h2.mobileOnly {
      margin: 32px 0 -20px;
    }
  }

  // Page Grid
  // ----------------------------------
  @media (min-width: $tablette) {
    display: grid;
    grid-template: 140px auto 1fr / 35% 60%;
    grid-gap: 0 5%;
    grid-template-areas:
      "retour contenu"
      "desc contenu"
      "info contenu";
    margin-bottom: 13vw;
  }

  @media (min-width: $medium) {
    margin-bottom: 8%;
  }
}
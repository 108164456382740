/**
 * @file Icônes
 * @author Christine Daneau-Pelletier <infocotegeek@gmail.com>
*/

/******************************************************************/
/* ICÔNES */
/******************************************************************/

.icone {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0 6px -2px 0;
  transition: all .25s ease-in-out;

  &--x {
    margin: 0;
    background: url("../images/icones/x.svg") no-repeat 0 0 / contain;
  }

  &--logout {
    background: url("../images/icones/logout.svg") no-repeat 0 0 / contain;
    filter: drop-shadow(.5px .5px .5px $tiel);
    transform: translateY(1px);
  }

  &--erreur {
    background: url("../images/icones/erreur.svg") no-repeat center / contain;
  }

  &--rights {
    background: url("../images/icones/info.svg") no-repeat 0 0 / contain;
  }

  // médias sociaux
  &__sociaux {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid var(--border);
    background: $blanc;
    z-index: 1;
    transition: all .18s ease-in-out;

    &_conteneur {
      width: 32px;
      height: 32px;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: -2px;
        left: -3px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: var(--medium);
        z-index: 0;
      }
    }
  }

  &--facebook {
    background: $blanc url("../images/icones/facebook.svg") no-repeat center / 12px;
  }

  &--youtube {
    //background: url("../images/icones/youtube.svg") no-repeat 0 0 / contain;
  }

  // Pour le player audio
  &--player, &--info {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 54px;
    height: 54px;
    background: #fff;
    border-radius: 50%;
    border: 1px solid var(--border);
    box-shadow: -3px -2px 0 0 var(--medium);
    color: var(--dark);
    transition: all .18s ease-in-out;
  }
}
/**
 * @file Boutons
 * @author Christine Daneau-Pelletier <infocotegeek@gmail.com>
*/

/**
 * ------------------------------------------------------
 * TABLE DES MATIÈRES
 * ------------------------------------------------------
 * Boutons de base
 * -- Bouton blanc
 * -- État disable
 *
 * Bouton de pour le menu hamburger (ouvert)
 */


// Boutons de base
// ------------------------------------------------------
.btn {
  transition: all .25s ease-in-out;
  padding: 0 32px;
  margin: 20px 6px;
  position: relative;
  height: 44px;
  line-height: 44px;
  background: $peach_light;
  border: 0;
  border-radius: 100px;
  box-shadow: inset 0 0 0 1.1px $charcoal;
  color: $charcoal;
  text-transform: uppercase;
  font-weight: 600;
  @include fontSize(12px);

  @media (min-width: $tablette) {
    .dark & {
      color: var(--text);
      background: var(--btn);
      box-shadow: inset 0 0 0 1.2px var(--text);
      text-shadow: none;
    }
  }

  &:hover, &:focus {
    color: $charcoal;
    background: $peach;
    outline: none;

    .dark & {
      @media (min-width: $tablette) {
        color: $blanc;
        background: $noir;
      }
    }
  }

  &:before, &:after {
    position: absolute;
    content: url("../images/btn_side_b.svg");

    .dark & {
      @media (min-width: $tablette) {
        content: url("../images/btn_side_w.svg");
      }
    }
  }

  &:before {
    transform: scaleX(-1);
    left: -6px;
  }

  &:after {
    right: -6px;
  }

  // Boutons de blanc
  &--blanc {
    background: transparent;

    &:hover, &:focus {
      background: $peach_light;

      .dark & {
        @media (min-width: $tablette) {
          background: $noir;
        }
      }
    }
  }

  // Simple
  &--simple {
    transition: all .25s ease-in-out;
    padding: 0 20px;
    position: relative;
    height: 44px;
    line-height: 44px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.14), 0 1px 6px -1px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    border: 1px solid $blanc;
    background: $blanc;

    &:hover, &:focus {
      border: 1px solid $bouteille;
      color: $charcoal;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.14), 0 1px 6px rgba(0, 0, 0, 0.3), 0 1px 10px rgba(0, 0, 0, 0.14);
    }
  }

  //État disable
  &:disabled {
    opacity: .5;
    filter: saturate(0);
  }
}
// ajutements dark
.dark {
  .btn--blanc {
    background: transparent;
  }
}


// Bouton de pour le menu hamburger (ouvert)
// ------------------------------------------------------
.menuBtn {
  padding: 24px;
  width: fit-content;
  transition: background-color .25s ease-in-out;

  @media (min-width: $mobile) {
    display: none;
  }

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
    background-color: $gris;
  }

  &__icone {
    width: 32px;
    height: 22px;
    position: relative;
  }

  &__ligne {
    position: absolute;
    background: $charcoal;
    width: 32px;
    height: 2px;
    border-radius: 4px;
    top: calc(50% - 1px);
    transition:  top .3s ease-in-out,  transform .3s ease-in-out .3s, opacity 0s ease-in-out .3s;

    &--1 {
      opacity: 0;
    }

    &--2 {
      transform: rotate(45deg);
    }

    &--3 {
      transform: rotate(135deg);
    }

    &--4 {
      clip-path: polygon(0% 0%, 0% 100%, 40% 100%, 40% 0, 58% 0, 58% 100%, 0 100%, 72% 100%, 72% 0%);
      opacity: 0;
    }
  }
}
/**
 * @file Styles pour la page À propos
 * @author Christine Daneau-Pelletier <infocotegeek@gmail.com>
*/

/**
 * ------------------------------------------------------
 * TABLE DES MATIÈRES
 * ------------------------------------------------------
 * Choeur & banner
 * -- BLock de texte blanc sur noir
 * -- --- hyperliens
 *
 * -- Texte en overflow & custom scrollbar
 *
 * box de presentation
 * -- FLIP the box
 * -- Photo de profil
 * -- --- cadre décoratif
 * -- Boite de texte
 * -- --- Décoration des points en bas
 * -- Grand titre qui passe par dessus la boîte
 * -- --- Décoration des points en haut
 * -- Texte en overflow & custom scrollbar
 * -- Ajustement d'un box spécifique
 * -- --- mots : joliette
 *
 * head des sliders avec ligne jaune
 *
 * Flèches des sliders
 *
 * slider partenaires
 *
 * slider concerts passés
 */

.apropos {
  // ----------------------------------
  // Choeur & banner
  // ----------------------------------
  &__choeur {
    position: relative;

    @media (max-width: $tablette) {
      margin-bottom: -50px;
    }

    &_img {
      position: relative;

      &:before, &:after {
        content: '';
        position: absolute;
        right: 0;
        height: 1px;
        @media (min-width: $tablette) {
          background: rgba($bleu, .5);
        }
      }

      &:before {
        top: 40px;
        width: 85vw;
        @media (min-width: $large) {
          top: 160px;
        }
      }
      &:after {
        bottom: 40px;
        width: 60vw;
        @media (min-width: $large) {
          bottom: 160px;
        }
      }

      @media (min-width: $tablette) {
        width: 100%;
        padding: 60px 0;
      }

      @media (min-width: $large) {
        padding: 200px 0;
      }
    }

    // BLock de texte blanc sur noir
    // ----------------------------------
    &_tx {
      margin: 0 8px;
      padding: 16px 32px 32px;
      background: $charcoal;
      color: $blanc;
      transform: translateY(-60px);
      z-index: 2;

      @media (min-width: $tablette) {
        transform: translateY(0);
        position: absolute;
        top: 0;
        right: 0;
        width: 50vw;
        max-height: 100%;
        overflow: hidden;
        margin: 0 40px;
        padding: 16px 50px 50px;
        box-shadow: 2px 4px 65px -5px rgba(0, 0, 0, 0.4);
      }
      @media (min-width: $medium) {
        padding: 16px 50px 50px;
        width: 35vw;
        margin: 0 6vw;
      }
    }

    // Texte en overflow & custom scrollbar
    // ----------------------------------
    &_contenu {
      @media (min-width: $tablette) {
        overflow: auto;
        max-height: calc(50vw - 114px);
        padding-right: 16px;
      }
      @media (min-width: $large) {
        max-height: calc(50vw + 110px);
      }
    }
  }


  // ----------------------------------
  // Box des présentations
  // ----------------------------------
  &__box { // un item
    display: flex;
    flex-flow: column;

    @media (min-width: $tablette) {
      flex-flow: row nowrap;
      align-items: flex-end;
      grid-gap: 5%;

      &:nth-of-type(odd) {
        flex-flow: row-reverse nowrap;
      }
    }

    // FLIP the box
    @media (min-width: $tablette) {
      &:nth-of-type(odd) {
        .apropos__box {
          &_titre {
            left: initial;
            right: 10px;

            // points
            &:before {
              right: initial;
              left: -10px;
            }

            &:after {
              right: initial;
              left: -30px;
            }
          }

          &_sousTitre {
            text-align: right;
          }

          &_contenu {
            // points
            &:before {
              right: initial;
              left: 10px;
            }

            &:after {
              right: initial;
              left: 20px;
            }
          }
        }
      }
    }

    // Photo de profil
    // ----------------------------------
    &_img {
      position: relative;
      margin: 18px 0 0 16px;
      max-width: 270px;
      min-width: 270px;

      @media (min-width: $large) {
        max-width: 380px;
        min-width: 380px;
      }

      // cadre décoratif
      &:after {
        content: '';
        position: absolute;
        top: -16px;
        left: -16px;
        border: 1px solid rgba($bleu, .75);
        width: 100%;
        height: 100%;
      }
    }

    // Boite de texte
    // ----------------------------------
    &_contenu {
      position: relative;
      border: 2px solid $aqua;
      border-radius: 3px;
      padding: 20px 16px 0;
      margin-bottom: 0;


      @media (min-width: $tablette) {
        padding: 20px 32px 32px;
        margin: 0;
      }

      @media (min-width: $large) {
        padding: 32px;
      }

      // Décoration des points en bas
      &:before , &:after {
        position: absolute;
        content: '';
        background: $blanc;
        height: 4px;
        bottom: -3px;
      }

      &:before {
        right: 10px;
        width: 8px;
      }

      &:after {
        right: 20px;
        width: 20px;
      }
    }

    // Grand titre qui passe par dessus la boîte
    // ----------------------------------
    &_titre {
      position: absolute;
      top: -50px;
      left: 0;
      margin: 16px;
      padding: 0 8px;
      //background: $blanc;
      color: $tiel;

      @media (min-width: $tablette) {
        top: -62px;
      }

      @media (min-width: $large) {
        top: -77px;
      }

      &In {
        z-index: 1;
        position: absolute;
        &--o {
          opacity: 0;
        }
      }

      // Décoration des points en haut
      &:before , &:after {
        position: absolute;
        content: '';
        background: $blanc;
        height: 5px;
        top: calc(50% + 2px);
        z-index: 0;
      }

      &:before {
        right: -10px;
        width: calc(100% + 16px);
      }

      &:after {
        right: -30px;
        width: 18px;
        clip-path: polygon(0% 0%, 0% 100%, calc(50% - 1px) 100%, calc(50% - 1px) 0, calc(50% + 1px) 0, calc(50% + 1px) 100%, calc(50% - 1px) 100%, calc(50% - 1px) 100%, 100% 100%, 100% 0%);
      }
    }

    &_sousTitre {
      font-weight: 500;
    }

    // Texte en overflow & custom scrollbar
    // ----------------------------------
    &_tx {
      @media (min-width: $tablette) {
        max-height: 120px;
        overflow: auto;
        padding-right: 16px;

        p:first-of-type {
          margin-top: 0;
        }

        p:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
  // Ajustement d'un box spécifique
  // ----------------------------------
  .apropos__chef {
    position: relative;

    @media (min-width: $tablette) {
      margin-top: 80px;
      margin-bottom: 180px;

      .apropos__box_tx {
        max-height: 200px;
      }
    }

    // mots : joliette
    &:before {
      @media (min-width: $tablette) {
        position: absolute;
        content: '';
        background: url("../images/mots/joliette.svg") no-repeat center / contain;
        width: 660px;
        height: 410px;
        left: 10%;
        bottom: -300px;
        z-index: -1;
      }
      @media (min-width: $large) {
        left: 5%;
        bottom: -400px;
      }
    }
  }

  // ----------------------------------
  // head des sliders avec ligne jaune
  // ----------------------------------
  &__head {
    &_titre {
      position: relative;
      width: min-content;
      padding-bottom: 12px;

      @media (min-width: $tablette) {
        padding-bottom: 20px;
      }

      @media (min-width: 470px) {
        width: fit-content;
      }

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 150vw;
        height: 3px;
        background: $ble;
        border-radius: 5px;

        @media (min-width: $tablette) {
          height: 4px;
        }

        .partenaires & {
          left: 30px;
        }

        .concerts & {
          right: -30px;

          @media (min-width: 470px) {
            right: 30px;
          }
        }
      }
    }
  }

  // ----------------------------------
  // Flèches des sliders
  // ----------------------------------
  .splide__arrow {
    svg {
      width: 40px;
      height: 40px;
    }
  }
}

// ----------------------------------
// slider partenaires
// ----------------------------------
.partenaires {
  position: relative;

  @media (min-width: $tablette) {
    padding: 0 0 100px;
  }

  // mots : sol de musique
  &:before {
    @media (min-width: $tablette) {
      position: absolute;
      content: '';
      background: url("../images/mots/sol_de_musique.svg") no-repeat center / contain;
      width: 840px;
      height: 420px;
      right: -140px;
      bottom: -300px;
      z-index: -1;
    }
    @media (min-width: $large) {
      right: -18%;
      width: 640px;
      height: 390px;
    }
  }

  &__slider {
    margin: 40px 0 60px;
    padding: 0 80px;

    @media (min-width: $tablette) {
      max-width: 50%;
      margin: 0;
    }

    @media (min-width: $tablette) AND (max-width: $bureau) {
      padding: 0 40px;
    }
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  &__tag {
    font-weight: 900;
    color: #007944;
    margin: -10px 0 10px 18.5vw;

    @media (min-width: $mobile) {
      margin: -20px 0 10px 100px;
    }

    @media (min-width: $tablette) {
      margin: -10px 0 20px;
    }

    @media (min-width: $medium) {
      margin: -20px 0 10px 100px;
    }
  }

  // PRINCIPAL
  &__principal {
    .partenaires { // ne rien mettre ici
      &__lien, &__img {
        width: 100%;

        @media (max-width: $tablette) {
          max-width: 500px;
        }
      }
    }

    @media (min-width: $tablette) {
      min-width: 40%;
    }
  }

  &__contenu {
    @media (min-width: $tablette) {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-end;
    }
  }

  .noSlider {
    display: flex;
    justify-content: space-evenly;
    width: -webkit-fill-available;
    //padding: 0;
    margin: 1%;
    padding: 20px 0;

    img {
      width: 180px;
      max-height: 100px;
    }

    @media (max-width: $mobile) {
      flex-flow: row wrap;
      gap: 30px;
      justify-content: right;

      img {
        width: 50vw;
      }
    }

    @media (min-width: $large) {
      margin: 1%;

      img {
        width: 260px;
      }
    }
  }
}

// ----------------------------------
// slider concerts passés
// ----------------------------------
.concerts {
  &__slider {
    max-width: 1200px;
    margin: 60px auto 0;
    padding: 0 80px;

    @media (min-width:1200px) {
      padding: 0 40px;
    }
  }

  .splide__arrow--next {
    right: 30px;

    &:not([disabled]):hover {
      right: 24px;
    }

    @media (min-width:1200px) {
      right: 0;

      &:not([disabled]):hover {
        right: -6px;
      }
    }
  }

  .splide__arrow--prev {
    left: 30px;

    &:not([disabled]):hover {
      left: 24px;
    }

    @media (min-width:1200px) {
      left: 0;

      &:not([disabled]):hover {
        left: -6px;
      }
    }
  }

  @media (min-width: $bureau) {
    &__actif {
      display: flex;
      grid-gap: 60px;

      &_titre {
        margin: -13px 0 10px;
        @include fontSize(32px);
        line-height: 1.5;
      }

      &_sousTitre {
        font-family: $fonteSecondaire;
        @include fontSize(20px);
        font-weight: 600;
        margin: 10px 0;
      }

      .concerts__img {
        width: 40%;
        min-width: 40%;
        max-height: 240px;
      }
    }
  }

  &__item {
    border: 3px solid transparent;

    &:focus {
      border-color: $coral;
    }

    .concerts__img {
      max-height: 132px;
      overflow: hidden;
    }
  }

  &__info {
    p:not(.concerts__actif_sousTitre) {
      margin: 0;
    }
  }
}
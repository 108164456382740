/**
 * @file Typographies
 * @author Christine Daneau-Pelletier <infocotegeek@gmail.com>
*/

/* facteur multiplicateur de la taille de la police 1.375x16=22 */

html {
  font-size: 62.5%;
}

body {
  @include fontSize(14px);
  color: var(--text);
  font-weight: 400;
  letter-spacing: .5px;
  font-family: $fontePrimaire;
  line-height: 1.5;

  @media (min-width:$mobile) {
    @include fontSize(16px);
  }

  @media (min-width:$tablette) {
    font-weight: 400;
    line-height: 1.75;
  }
}

h1, .h1,
h3, .h3 {
  font-family: $fonteSecondaire;
}

h1, .h1,
h2, .h2,
h3, .h3 {
  text-transform: uppercase;
  font-weight: 400;
}

h2, .h2,
h4, .h4 {
  font-family: $fontePrimaire;
}

h1, .h1 {
  @include fontSize(40px);

  @media (min-width:$tablette) {
    @include fontSize(60px);
    line-height: 1.35;
  }
  @media (min-width:$large) {
    @include fontSize(80px);
  }
}

h2, .h2 {
  @include fontSize(24px);

  @media (min-width:$tablette) {
    @include fontSize(32px);
  }
  @media (min-width:$large) {
    @include fontSize(48px);
  }
}

h3, .h3 {
  text-shadow: 0 0 .5px $noir;
  @include fontSize(20px);

  @media (min-width:$tablette) {
    @include fontSize(24px);
  }
}

h4, .h4 {
  @include fontSize(16px);

  @media (min-width:$tablette) {
    @include fontSize(20px);
  }
}

.p {
  @include fontSize(14px);
  font-weight: 400;
  margin: 0;
  text-transform: initial;

  @media (min-width:$mobile) {
    @include fontSize(16px);
  }
}

.accent {
  @include fontSize(16px);
  font-weight: 500;
  letter-spacing: .5px;
  font-family: $fontePrimaire;
  text-transform: initial;

  @media (min-width:$tablette) {
    @include fontSize(18px);
  }
}

sup {
  text-transform: lowercase;
}
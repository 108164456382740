/**
 * @file Styles pour l'accueil
 * @author Christine Daneau-Pelletier <infocotegeek@gmail.com>
*/

/**
 * ------------------------------------------------------
 * TABLE DES MATIÈRES
 * ------------------------------------------------------
 * Bannière - slider
 * Introduction
 * -- flex
 * -- mots : chant choral
 * Galerie de photos
 * Actualités
 * mots : choeur du musée
 */


// ----------------------------------
// Bannière - slider
// ----------------------------------
.banniere {
  @media (max-width: $tablette) {
    margin: 0 0 100px;

    img {
      clip-path: polygon(33.33% 0, 100% 0, 100% 100%, 33.33% 100%);
      width: 150%;
      margin: 0 0 0 -50%;
    }
  }

  &__info {
    padding: 20px 0;

    @media (min-width: $tablette) {
      max-width: 50%;
      min-width: 400px;
      height: fit-content;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto 0 auto 48px;
      padding: 20px 0 calc(40px + 34px);
      color: var(--text);
    }

    @media (min-width: $bureau) {
      margin: auto 0 auto calc(4vw + 42px);
    }
  }

  &__titre {
    padding: 20px 0;

    @media (min-width: $tablette) {
      margin: 0;
      padding: 0;
    }
  }

  &__sousTitre {
    line-height: 1.25;
    max-width: 520px;

    @media (min-width: $tablette) AND (max-width: $bureau) {
      display: none;
    }
  }

  &__date {
    font-weight: 600;
  }

  .btn {
    margin-bottom: 0;
  }

  &__billets {
    margin-right: 26px;
  }
}

// Fix de FIREFOX
@media (min-width: $tablette) {
  @-moz-document url-prefix() {
    .banniere__info {
      display: inline-flex;
      flex-flow: column nowrap;
      justify-content: center;

      .btn {
        width: min-content;
        white-space: nowrap;
      }
    }
  }
}

// ----------------------------------
// Introduction
// ----------------------------------
.intro {
  position: relative;

  img {
    width: initial;
    height: initial;
    min-width: initial;
    vertical-align: sub;
  }

  &__tag {
    width: fit-content;
    margin-bottom: 32px;

    &:before, &:after {
      background: $peach;
    }
  }

  &__lien {
    color: $rouge;
    line-height: 1.5;

    &_container {
      display: inline-block;
      text-align: right;
      width: 100%;

      @media (min-width: $mobile) AND (max-width: $bureau) {
        text-align: left;
      }
    }
  }

  &__logo {
    color: var(--couleurAdmin);
    width:fit-content;
    position: relative;
    margin: 32px auto;
    display: none;

    svg {
      width: 200px;
      height: 200px;
    }

    @media (min-width: $mobile) {
      margin: 0;
      display: block;

      svg {
        width: 25vw;
        height: 25vw;
        max-width: 240px;
        max-height: 240px;
      }
    }

    @media (min-width: $large) {
      svg {
        max-width: 300px;
        max-height: 300px;
      }
    }
  }

  // flex
  @media (min-width: $mobile) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-end;
    grid-gap: 6vw;
  }

  // mots : chant choral
  &:before {
    position: absolute;
    content: '';
    background: url("../images/mots/chant_choral.svg") no-repeat center / contain;
    width: 120%;
    height: 50%;
    left: -10%;
    bottom: -36%;
    z-index: -1;

    @media (min-width: $mobile) {
      bottom: -30%;
      left: 0;
    }

    @media (min-width: $tablette) {
      height: 440px;
      bottom: -60%;
    }

    @media (min-width: $medium) {
      bottom: -77%;
    }

    @media (min-width: $large) {
      bottom: -51%;
      left: -5%;
    }
  }
}

// ----------------------------------
// Galerie de photos
// ----------------------------------
.galerie {
  padding: 0 0 20px;

  @media (min-width: $mobile) {
    padding: 80px 0;
  }

  &__item {
    margin: auto;
  }

  &__img {
    transition: all .25s ease-in-out;
    filter: saturate(0);
    clip-path: polygon(0 15%, 100% 15%, 100% 85%, 0 85%);

    li.is-active & {
      filter: initial;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);

      @media (min-width: $mobile) {
        transform: scale(1.25);
        margin-top: 0;
      }
    }
  }

  .splide {
    --gap: 16px; // (2 x 40)
    --pad: 0vw;
    --w: 40px;
    --h: 24px;

    @media (min-width: $mobile) {
      --gap: 128px;
      --pad: 10vw;
      --w: 74px;
    }

    @media (min-width: $bureau) {
      --gap: 300px;
      --pad: 30vw;
      --w: 74px;
    }

    &__track {
      overflow: visible;
    }

    &__arrow {
      width: var(--w);
      height: var(--h);
      top: 113%;

      @media (min-width: $mobile) AND (max-width: $bureau) {
        top: 125%;
      }

      svg {
        width: var(--w);
        height: var(--h);
      }

      &--prev {
        left: calc(var(--pad) - (var(--gap) - var(--w)));

        &:hover {
          left: calc(var(--pad) - (var(--gap) - (var(--w) - 10px)));
        }
      }

      &--next {
        right: calc(var(--pad) - (var(--gap) - var(--w)));

        &:hover {
          right: calc(var(--pad) - (var(--gap) - (var(--w) - 10px)));
        }
      }
    }
  }

  .copyrights {
    top: 106%;
    left: 40px;
    width: 70%;
    transition: opacity .2s ease-in-out .35s;

    @media (min-width: $mobile) {
      width: 110%;
      top: 120%;
      left: -5%;
    }

    @media (min-width: $tablette) {
      width: 100%;
      top: 116%;
      left: -12%;
    }
  }

  li.is-active .copyrights {
    opacity: 1;
  }
}

// ----------------------------------
// Actualités
// ----------------------------------
.actualites {
  @media (min-width: $tablette) {
    padding-bottom: 60px;
  }
  @media (min-width: $medium) {
    padding-bottom: 120px;
  }

  &__head_titre {
    width: fit-content;

    &:before, &:after {
      background: $coral;
    }
  }

  &__list {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr;
    margin: 32px 0;

    @media (min-width: $tablette) {
      grid-template-columns: repeat(2, 48%);
      grid-gap: 0 4%;
    }

    @media (min-width: $medium) {
      grid-template-columns: repeat(2, calc(50% - 20px));
      grid-gap: 0 40px;
    }
  }

  &__lien {
    color: $charcoal;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    grid-gap: 5%;
    padding: 12px 0;
    font-weight: 400;

    &:hover, &:focus {
      color: $tiel;
      background-color: $gris;

      .actualites__img {
        transform: translateX(4px);
      }
    }
  }

  &__img {
    min-width: 100px;
    max-width: 100px;
    transition: transform .2s ease-in-out;
  }

  &__titre {
    margin: 0;
  }

  &__date {
    font-style: italic;
  }

  &__date, &__source {
    @include fontSize(12px);
    margin: 4px 0;

    @media (min-width:$mobile) {
      @include fontSize(14px);
    }
  }
}

.accueil {
  position: relative;
  overflow: hidden;

  // mots : choeur du musée
  &:after {
    position: absolute;
    content: '';
    background: url("../images/mots/choeur_du_musee.svg") no-repeat center / contain;
    width: 104%;
    height: 410px;
    left: 50%;
    transform: translateX(-50%);
    bottom: -80px;
    z-index: -1;
    opacity: 0;

    @media (min-width: $tablette) {
      opacity: 1;
    }

    @media (min-width: $medium) {
      bottom: -20px;
    }
  }
}
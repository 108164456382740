/**
 * @file Pied de page
 * @author Christine Daneau-Pelletier <infocotegeek@gmail.com>
*/

/**
 * ------------------------------------------------------
 * TABLE DES MATIÈRES
 * ------------------------------------------------------
 * Styles généraux
 * Logos
 * réseaux sociaux
 *
 */


// Styles généraux
// ------------------------------------------------------
.pied {
  background: $gris;

  @media (min-width: $medium){
    &.block--pad {
      padding: 100px 0 80px;
    }
  }

  &__contenu {
    display: flex;
    flex-flow: row wrap;
  }

  &__form {
    max-width: 320px;
    width: 100%;

    p {
      margin: 0;
    }

    input {
      margin-bottom: 6px;
      box-shadow: -1px -1px 1px 1px $blanc, 1px 1px 7px 0px #eaeaea;
    }
  }

  .btn, .infoContact {
    margin-bottom: 48px;
  }

  .ctnForm{
    margin-top: 0;
    margin-bottom: 0;
  }
}

// réseaux sociaux
.sociaux__lien  {
  display: flex;
  align-items: center;
  grid-gap: 12px;
  margin: 0 0 12px;
  width: fit-content;

  &:hover {
    .icone__sociaux {
      top: -2px;
      left: -3px;
    }
  }
}

.infoContact, .credits {
  width: 100%;
}

.infoContact {
  @media (min-width: $bureau) AND (max-width: $medium) {
    max-width: 25vw;
  }

  @media (min-width: $bureau) {
    float: right;
    width: fit-content;
    margin-left: auto;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;
    text-align: right;
  }
}

.credits {
  @media (min-width: $mobile) {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    grid-gap: 40px;
    line-height: 1;

    .credits__tx {
      margin: 0;
    }
  }

  &__tx {
    margin-top: 0;
  }

  &__btn {
    white-space: nowrap;
    
    &:hover, &:focus {
      cursor: pointer;
    }
  }
}
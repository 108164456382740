/**
 * @author Christine Daneau-Pelletier <infocotegeek@gmail.com>
*/

/** Importation des fonts **/
@import url('https://fonts.googleapis.com/css2?family=Poiret+One&family=Quicksand:wght@300;400;500;600;700&display=swap');

/** Polices de caractères **/

$fontePrimaire: 'Quicksand', Arial, sans-serif;
$fonteSecondaire: 'Poiret One', cursive;

/** Palette de couleurs **/

// Utiliser http://chir.ag/projects/name-that-color
// pour obtenir des noms uniques et mémorisables
// Permettez-vous d'utiliser un nom de variable plus explicite
// si la suggestion de name-that-color est trop étrange

/* Couleurs de base */
$noir: #000;
$charcoal: #202020;
$charcoal_light: rgba($charcoal, .7);
$rouge: #D7402C;
$coral: #FE6955;
$peach: #FFB2A8;
$peach_light: lighten($peach, 11);
$rose_dust: #D1B3A7;
$ocre: #D18E0A;
$jaune: #EEAD2F;
$ble: #FFD37D;
$foret: #60902F;
$vert: #92B56F;
$lime: #D2EBB9;
$cascade: #92B0A3;
$tiel: #125055;
$aqua: #337A80;
$bleu: #77B8BD;
$bordeau: #b4d8c7;//BEDECF
$bordeau_light: rgba($bordeau, .7);
$bouteille: #C8DCD3;
$blanc: #FBFBFB;
$gris: #F5F5F5;

$erreur: $rouge;

/** Thèmes **/
:root {
  --text: #{$charcoal};
  --btn: #{$peach_light};
  --light: #{$bouteille};
  --medium: #{$bordeau_light};
  --border: #{$charcoal_light};
  --dark: #{$tiel};
  --neon: #{$aqua};
}

.dark {
  --text: #{$blanc};
  --btn: var(--couleurAdmin);
}

/** Points de rupture **/
$huge: 1920px;
$large: 1440px;
$medium: 1200px;
$bureau : 1024px;
$tablette : 770px;
$mobile : 520px;
$miniMobile : 360px;